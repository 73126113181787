import { userAxios } from '../../index'

const visitStatusList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/visitStatuses/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusCreate = async data => {
  try {
    return await userAxios.post('insurance/visitStatuses', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/visitStatuses/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusDelete = async id => {
  try {
    return await userAxios.delete(`insurance/visitStatuses/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/visitStatuses/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/visitStatuses/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const changeStatusOfficeVisit = async (office_visit_id, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/change-status/${office_visit_id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const changeOmaOfficeVisit = async (oma, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/change-oma/${oma}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitStatusFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/visitStatuses/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  visitStatusList,
  visitStatusCreate,
  visitStatusUpdate,
  visitStatusDelete,
  visitStatusPagination,
  visitStatusFilter,
  visitStatusFilterPagination,
  changeStatusOfficeVisit,
  changeOmaOfficeVisit,
}
