<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Patient") }}</label>
          <b-form-input autofocus v-model.trim="filters.patient_name" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Type User") }}</label>
          <treeselect v-model="filters.user_type" :options="TypeUserOptions" :clearable="true" @keyup.enter="searchFilter"
            :normalizer="normalizer" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Types of visits") }}</label>
          <treeselect v-model="filters.type_visits_id" :options="typeVisitOptions" :clearable="true"
            @keyup.enter="searchFilter" :normalizer="normalizer" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Room") }}</label>
          <treeselect v-model="filters.room" :options="roomOptions" :clearable="true" @keyup.enter="searchFilter"
            :normalizer="normalizer" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Nurse</label>
          <b-form-input autofocus v-model.trim="filters.nurse" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Oma</label>
          <b-form-input autofocus v-model.trim="filters.oma" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Frontdesk</label>
          <b-form-input autofocus v-model.trim="filters.frontdesk" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Status") }}</label>
          <treeselect v-model="filters.visit_statuses_id" :async="true" :load-options="getVisitStatus"
            :defaultOptions="true" :clearable="true" @keyup.enter="searchFilter" :normalizer="normalizer" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Payment status</label>
          <treeselect v-model="filters.pay_status_id" :async="true" :load-options="getCategoryStatus"
            :defaultOptions="true" :clearable="true" @keyup.enter="searchFilter" :normalizer="normalizer" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Start Date") }}</label>
          <flat-pickr v-model="filters.visit_date" :config="config" class="form-control" placeholder="MM-DD-YYYY"
            type="date" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.End Date") }}</label>
          <flat-pickr v-model="filters.date_end" :config="configEnd" class="form-control" placeholder="MM-DD-YYYY"
            type="date" />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosLC from "@/core/services/apiInsurance/admin/consults/visits";
import axiosS from "@/core/services/apiInsurance/admin/consults/visitStatus";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateOptions: [],
      typeVisitOptions: [],
      visitStatusOptions: [],
      paymentStatusOptions: [],
      roomOptions: [{ name: 1, id: 1 }, { name: 2, id: 2 }, { name: 3, id: 3 }, { name: 4, id: 4 }, { name: 5, id: 5 }, { name: 6, id: 6 }, { name: 7, id: 7 }],
      filters: {
        patient_name: null,
        user_type: null,
        type_visits_id: null,
        room: null,
        nurse: null,
        oma: null,
        visit_statuses_id: null,
        frontdesk: null,
        visit_date: null,
        date_end: null,
      },
      statusOptions: [
        { name: "Active", id: "true" },
        { name: "Inactive", id: "false" },
      ],
      TypeUserOptions: [
        { name: "Established", id: "established" },
        { name: "New", id: "new" },
      ],
      config: {
        dateFormat: "m-d-Y H:i:S",
        enableSeconds: true,
        allowInput: true,
        enableTime: true
      },
      configEnd: {
        dateFormat: "m-d-Y H:i:S",
        enableSeconds: true,
        allowInput: true,
        enableTime: true
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    const datexd = `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`
    this.filters.visit_date = `${datexd} 00:00:00`
    this.filters.date_end = `${datexd} 23:59:59`
    this.searchFilter();
    this.getCategory();
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    getCategory() {
      axiosLC.visitList(10).then(({ data }) => {
        this.typeVisitOptions = data;
      });
    },
    getCategoryStatus({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        axiosLC.getPaymentStatus().then(({ data }) => {
          callback(null, data);
        });
      }
    },
    getVisitStatus({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        axiosS.visitStatusList(50).then(({ data }) => {
          callback(null, data);
        });
      }
    },
  },
};
</script>
